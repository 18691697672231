<template>
  <div>
    <BaseModal name="withdraw-request" size="md" :title="$t('seller.statements.modals.withdraw.text_829')"
      @shown="openModal" @hidden="close">
      <!-- Loading -->
      <div class="d-flex justify-content-center" v-if="loading || loadingKyc">
        <b-spinner label="Loading..."></b-spinner>
      </div>

      <div v-if="showWithdrawal">
        <!-- Select Currency Wallet -->
        <!-- <div v-if="isHeaven" class="mb-3 d-flex align-items-center">
          <b-form novalidate class="w-100 mr-2">
            <b-form-group label="Selecione uma carteira:">
              <b-form-select
                v-model="wallet_currency"
                class="mb-3"
                v-validate="'required'"
                name="wallet_currency"
                :class="{'heaven': isHeaven}"
                @change="updateCoin()"
              >
                <b-form-select-option v-for="currency in base_coins" :key="currency.id"
                  :value="currency"
                >
                  {{ currency.name }}
                </b-form-select-option>
              </b-form-select>
              <b-form-invalid-feedback :state="!errors.has('wallet_currency')">
                Por favor, selecione uma carteira
              </b-form-invalid-feedback>
            </b-form-group>
          </b-form>
          <div class="mt-3 d-flex align-items-center">
            <div class="chip" :style="setColors(wallet_currency.name)">
              {{ wallet_currency.currency_symbol }}
            </div>
            <span>{{ wallet_currency.currency }}</span>
          </div>
        </div> -->
        <!-- Avaliable Balance -->
        <div class="item-top justify-content-between">
          <div class="item-top">
            <img src="@/assets/img/icons/money-free.svg" />
            <div class="money">
              <span>{{ $t('views.seller.statements.balance_available') }}</span>
              <p>
                {{ currency_symbol_avaliable }}
                {{ valor_disponivel ? formatMoney(valor_disponivel) : "0,00" }}
              </p>
            </div>
          </div>
        </div>
        <!-- Anticipate -->
        <div class="item-top item-antecipa" v-if="permite_antecipacao && status_antecipation !== 'REQUESTED'">
          <!-- Anticipate Amount -->
          <div class="item-top">
            <img src="@/assets/img/icons/money-anticipation.svg" />
            <div class="money">
              <span>{{ $t('seller.statements.modals.antecipation.text_780') }}</span>
              <p>
                {{ currency_symbol_avaliable }}
                {{ valor_antecipavel ? formatMoney(valor_antecipavel) : "0,00" }}
              </p>
            </div>
          </div>
          <!-- Want Anticipate -->
          <div id="quero-antecipar">
            <BaseButton v-if="permite_antecipacao" :disabled="0 >= valor_antecipavel" variant="black" class="btn-saldo"
              @click="openAntecipation">
              ⚡ {{$t('seller.statements.modals.antecipation.text_793')}}
            </BaseButton>
          </div>
          <!-- Want Anticipate Error Menssage -->
          <b-tooltip v-if="0 >= valor_antecipavel" target="quero-antecipar"
            title="Você não possui valores para antecipar" />
        </div>
        <!-- Feedback Menssage -->
        <div class="text-info mt-3">
          <div v-if="status_antecipation === 'REQUESTED'" class="aprovacao btn-2 mt-2 mb-3">
            <img src="@/assets/img/icons/info-prod.svg" />
            {{ $t('seller.statements.modals.withdraw.text_832') }}
            {{ lastAntecipationCurrencySymbol }}
            {{ formatMoney(last_antecipation.amount) }}. {{ $t('seller.statements.modals.withdraw.text_854') }}
          </div>
          <div v-if="status_antecipation === 'REFUSED'" class="aprovacao DISAPPROVED btn-2 mt-2 mb-3">
            <img src="@/assets/img/icons/info-prod-reprovado.svg" />
            {{ $t('seller.statements.modals.withdraw.text_833') }}
          </div>
        </div>
        <!-- Withdrawal Amount -->
        <div class="mb-3 mt-4">
          <h5 class="topico">{{ $t('seller.statements.modals.withdraw.text_834') }}</h5>
          <b-form novalidate>
            <b-form-group label-for="amount">
              <money id="amount" v-model="amount" v-bind="money" v-validate="'required'" autocomplete="off" disable
                :class="{'heaven': isHeaven}"></money>
              <b-form-invalid-feedback :state="!amount_error">
                {{ $t('seller.statements.modals.withdraw.text_835') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-form>
        </div>
        <!-- Select Bank Account -->
        <h5 class="topico">{{ $t('seller.statements.modals.withdraw.text_836') }}</h5>
        <div class="mb-3 d-block d-md-flex align-items-center">
          <b-form novalidate>
            <b-form-group label-for="bank_account">
              <b-form-select class="select-contas-bancarias" v-model="bank_account" name="bank_account"
                :options="accounts" v-validate="'required'" :class="{'heaven': isHeaven}"
                @change="updateBankAccount()"></b-form-select>
              <b-form-invalid-feedback :state="!errors.has('bank_account')">
                {{ $t('seller.statements.modals.withdraw.text_837') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-form>
          <!-- Add Bank Account -->
          <b-tooltip target="btn-conta" :title="$t('seller.statements.modals.withdraw.text_838')" />
          <BaseButton id="btn-conta" variant="link-info" class="btn-conta-corrente" @click="openBanks">
            <img src="@/assets/img/icons/card-outline.svg" />
            <span class="d-md-none d-block ml-2">{{ $t('seller.statements.modals.bank_account.text_813') }}</span>
          </BaseButton>
        </div>
        <!-- Select Coin -->
        <!-- <div v-if="bank_account && coins.length > 0" class="mb-3 mt-4">
          <b-form novalidate>
            <b-form-group label="Selecione a moeda de saque:">
              <b-form-select
                v-model="selected_currency"
                class="mb-3"
                v-validate="'required'"
                name="selected_currency"
                @change="updateCurrency()"
              >
                <b-form-select-option :value="null">Selecione uma moeda</b-form-select-option>
                <b-form-select-option v-for="currency in coins" :key="currency.id"
                  :value="currency"
                >
                  {{ currency.name }}
                </b-form-select-option>
              </b-form-select>
              <b-form-invalid-feedback :state="!errors.has('selected_currency')">
                Por favor, selecione uma moeda
              </b-form-invalid-feedback>
            </b-form-group>
          </b-form>
        </div> -->
        <!-- Feedback Menssage -->
        <!-- <p v-if="coins.length === 0" class="mb-4" style="font-size: smaller;">
          Essa conta bancária não possui moedas cadastradas, favor atualizar os dados. Caso erro persista entre em contato com o suporte!
        </p> -->
      </div>
      <!-- Add Bank Account -->
      <div v-if="showAddBankAccount" class="sem-contas">
        <p>{{ $t('seller.statements.modals.withdraw.text_839') }}</p>
        <BaseButton variant="black" class="mt-3" v-b-modal.bank-account>
          {{ $t('seller.statements.modals.withdraw.text_840') }}
        </BaseButton>
      </div>
      <!-- Withdrawal Details -->
      <div v-if="showWithdrawalDetails" class="detalhes-saque" data-anima="top">
        <h5 class="topico topico_detalhes">{{ $t('seller.statements.modals.withdraw.text_841') }}</h5>
        <!-- Account Details National -->
        <div v-if="conta_selecionada.bic_swift == null" class="item-detalhe">
          <span>{{ $t('seller.statements.modals.bank_account.text_810') }}</span>
          <div class="info-detalhe">
            <p>{{ $t('seller.statements.modals.bank_account.text_2719') }}</p>
            <p class="value-info">{{ conta_selecionada.cpf_cnpj }}</p>
          </div>
          <div class="info-detalhe">
            <p>{{ $t('seller.statements.modals.bank_account.text_2717') }}</p>
            <p class="value-info">{{ conta_selecionada.bank_name }}</p>
          </div>
          <div class="info-detalhe">
            <p>{{ $t('seller.statements.modals.bank_account.text_2713') }}</p>
            <p class="value-info">{{ conta_selecionada.agency }}</p>
          </div>
          <div class="info-detalhe">
            <p>{{ $t('seller.statements.modals.bank_account.text_2714') }} {{ conta_selecionada.type === "CURRENT" ?
              "Corrente" : "Poupança" }}</p>
            <p class="value-info">{{ conta_selecionada.account }}-{{ conta_selecionada.account_dv }}</p>
          </div>
          <div class="info-detalhe">
            <p>{{ $t('seller.statements.modals.bank_account.text_840') }}</p>
            <p class="value-info" v-if="conta_selecionada.account_type"> {{
              $t(`seller.statements.modals.bank_account.${conta_selecionada.account_type}`) }}</p>
          </div>
        </div>
        <!-- Account Details International -->
        <!-- <div v-else class="item-detalhe">
          <span>Dados da conta</span>
          <div class="info-detalhe">
            <p>Nome do Titular</p>
            <p class="value-info">{{ conta_selecionada.name }}</p>
          </div>
          <div class="info-detalhe">
            <p>Tipo de Conta</p>
            <p class="value-info">{{ conta_selecionada.international_type === 'IBAM' ? 'IBAM' : 'Conta Bancária' }}</p>
          </div>
          <div class="info-detalhe">
            <p>{{ conta_selecionada.international_type === 'IBAM' ? 'IBAM' : 'Número da Conta' }}</p>
            <p class="value-info">{{ conta_selecionada.account }}</p>
          </div>
          <div class="info-detalhe">
            <p>BIC_SWIFT</p>
            <p class="value-info">{{ conta_selecionada.bic_swift }}</p>
          </div>
          <div class="info-detalhe">
            <p>País</p>
            <p class="value-info">{{ conta_selecionada.country }}</p>
          </div>
        </div> -->
        <!-- Amount Details -->
        <div class="item-detalhe" v-if="amount">
          <span>{{ $t('seller.statements.modals.withdraw.text_855') }}</span>
          <!-- Requested Amount -->
          <div class="info-detalhe">
            <p>{{ $t('seller.statements.modals.withdraw.text_856') }}</p>
            <p class="value-info">
              {{ currency_symbol_avaliable }}
              {{ formatMoney(amount) }}
            </p>
          </div>
          <!-- Converted Value -->
          <!-- <div v-if="!loadingConvertion && selected_currency != null && wallet_currency != null && (selected_currency != wallet_currency)"
            class="info-detalhe"
          >
            <p>Valor convertido</p>
            <p class="value-info">
              {{ currency_symbol_details }}
              {{ formatMoney(currency_conversion) }}
            </p> -->
          <!-- Loading -->
          <!-- <div v-if="loadingConvertion" class="d-flex justify-content-center">
              <b-spinner label="Loading..."></b-spinner>
            </div>
          </div> -->
          <!-- Withdrawal Fee -->
          <div class="info-detalhe">
            <p>{{ $t('views.seller.statements.withdraw_fee') }}</p>
            <p class="value-info taxa-info">
              - {{ currency_symbol_avaliable }}
              {{ formatMoney(taxa_saque) }}
            </p>
          </div>
          <!-- Conversion Fee -->
          <!-- <div v-if="selected_currency != null && wallet_currency != null && (selected_currency != wallet_currency)" class="info-detalhe">
            <p>Taxa de Conversão</p>
            <p class="value-info taxa-info">
              - {{ currency_symbol_details }}
              {{ formatMoney(currency_fee) }}
            </p>
          </div> -->
          <!-- Will Be Transferred -->
          <div v-if="selected_currency != null && wallet_currency != null" class="info-detalhe">
            <p>{{ $t('seller.statements.modals.withdraw.text_857') }}</p>
            <p class="value-info">
              {{ currency_symbol_details }}
              {{ selected_currency.id === wallet_currency.id
              ? formatMoney(amount - taxa_saque)
              : formatMoney(currency_conversion - taxa_saque - currency_fee) }}
            </p>
          </div>
        </div>
        <!-- Feedback Menssage -->
        <div class="item-detalhe" v-else>
          <span>{{ $t('seller.statements.modals.withdraw.text_842') }}</span>
        </div>
      </div>
      <!-- Identity Verification KYC/PF/PJ -->

      <div v-if="showIdentityVerification" class="verificao">
        <template v-if="pendingIdentityValidation">
          <div v-if="status_validation.status === 'REQUESTED'">
            <p>
              {{ $t('seller.statements.modals.withdraw.text_858') }}
            </p>
            <p>
              {{ $t('seller.statements.modals.withdraw.text_844') }}
            </p>
          </div>
          <div v-else-if="status_validation.status === 'UPDATE_VALIDATION'">
            <p>
              {{ $t('views.seller.my_account.text_1235_1') }}
            </p>
            <BaseButton variant="black" @click="initVerification"
              v-if="!isWebView || isWebView && getFlags.includes('idwall')">
              {{ $t('seller.statements.modals.withdraw.text_847') }}
            </BaseButton>
          </div>
          <div v-else>
            <div v-if="!validation_initiated">
              <p>{{ $t('seller.statements.modals.withdraw.text_845') }}</p>
              <p>
                {{ $t('seller.statements.modals.withdraw.text_859') }}
              </p>
            </div>
            <p v-else>
              {{ $t('seller.statements.modals.withdraw.text_885') }}
            </p>
            <BaseButton variant="black" @click="initVerification"
              v-if="(!isWebView || isWebView && getFlags.includes('idwall') ) && flowsAreValid">
              {{ $t('seller.statements.modals.withdraw.text_847') }}
            </BaseButton>
          </div>
        </template>
        <template v-else-if="getFlags.includes('recipient_data') && pendingKycPersonalChecklist">
          <p>{{$t('seller.statements.modals.withdraw.text_869')}}</p>
          <p>
            {{$t('seller.statements.modals.withdraw.text_870')}}
          </p>
          <BaseButton variant="black" @click="openAccountTypeModal">
            {{$t('seller.statements.modals.withdraw.text_871')}}
          </BaseButton>
        </template>
        <template v-else-if="getFlags.includes('recipient_data') && pendingPjDocuments">
          <p>{{$t('seller.statements.modals.withdraw.text_872')}}</p>
          <p>
            {{$t('seller.statements.modals.withdraw.text_873')}}
          </p>
          <BaseButton variant="black" @click="openPJModal">
            {{$t('seller.statements.modals.withdraw.text_874')}}
          </BaseButton>
        </template>
        <template v-else-if="getFlags.includes('recipient_data') && waitingPjApprovel">
          <p>
            {{ $t('seller.statements.modals.withdraw.text_875') }}
          </p>
          <p>
            {{ $t('seller.statements.modals.withdraw.text_844') }}
          </p>
        </template>
        <template v-else-if="getFlags.includes('recipient_data') && errorCreateCelcoin.length">
          <p>{{$t('seller.statements.modals.withdraw.text_876')}}</p>
          <p>
            {{$t('seller.statements.modals.withdraw.text_877')}}
          </p>
          <BaseButton variant="black" @click="openAccountTypeModal">
            {{$t('seller.statements.modals.withdraw.text_878')}}
          </BaseButton>
        </template>
        <template
          v-else-if="getFlags.includes('recipient_data') && (errorKycCelcoinPF.length || errorKycCelcoinPJ.length)">
          <template v-if="errorKycCelcoinPF.length">
            <p>{{$t('seller.statements.modals.withdraw.text_879')}}</p>
            <p>
              {{$t('seller.statements.modals.withdraw.text_880')}}
            </p>
            <BaseButton variant="black" @click="initVerification">
              {{$t('seller.statements.modals.withdraw.text_881')}}
            </BaseButton>
          </template>
          <template v-else-if="errorKycCelcoinPJ.length">
            <p>{{$t('seller.statements.modals.withdraw.text_882')}}</p>
            <p>
              {{$t('seller.statements.modals.withdraw.text_883')}}
            </p>
            <BaseButton variant="black" @click="openPJModal">
              {{$t('seller.statements.modals.withdraw.text_884')}}
            </BaseButton>
          </template>
        </template>
      </div>
      <!-- Footer -->
      <template v-slot:footer="{ cancel }">
        <BaseButton variant="link-info" class="mr-4" @click="cancel" :disabled="!!loading || !!loadingKyc">
          {{ $t('views.cobranca.text_2167') }}
        </BaseButton>
        <div id="btn-sacar">
          <BaseButton variant="info2" @click="onSubmit" :disabled="!!(loading || loadingKyc || pre_infos || amount < (isHeaven ? 20 : 100) || wallet_currency == null ||
              (getFlags.includes('recipient_data') && (pendingKyc || errorCreateCelcoin.length || errorKycCelcoinPF.length || errorKycCelcoinPJ.length)))
            ">
            {{ $t('seller.statements.modals.withdraw.text_848') }}
          </BaseButton>
        </div>
        <b-tooltip v-if="amount < (isHeaven ? 20 : 100)" target="btn-sacar"
          :title="$t('seller.statements.modals.withdraw.text_849') +' ' + currency_symbol_avaliable + (isHeaven ? ' 20,00' : ' 100,00')" />

        <b-tooltip v-if="pre_infos && amount > 99" target="btn-sacar"
          :title="$t('seller.statements.modals.withdraw.text_850')" />
      </template>
    </BaseModal>

    <Antecipation :antecipation="valor_antecipavel" :antecipation_fee="antecipation_fee"
      :antecipation_status="status_antecipation" :currency="wallet_currency" @close="fetchReports(wallet_currency.id)"
      @pending="setPending" />
    <ModalValidation :id="id_modal_identity_validation" @close="getStatusValidation('close_modal')" />
    <ModalBankAccount :modalName="name" @close="fetchAccounts()" />
    <UserValidation />
    <ModalAccountType :id="id_modal_account_type" :recebedorType="account_type" @close="closeAccountType" />
    <ModalPJAccount />
  </div>
</template>

<script>
import { EventBus } from "@/main.js";
import { Money } from "v-money";
import FormatMoney from "@/mixins/money";
import ModalBankAccount from './bankAccount.vue'
import debounceCookies from '@/utils/debounceCookies.js'
import _refiner from 'refiner-js'

import UserValidation from '@/components/Seller/MyAccount/modals/UserValidation.vue'
import ModalPJAccount from '@/components/Seller/Settings/ModalPJAccount.vue'
import ModalAccountType from '@/components/Seller/Settings/ModalAccountType.vue'

import WithdrawService from "@/services/resources/WithdrawService";
import BankAccountService from "@/services/resources/BankAccountService";
import DashboardService from "@/services/resources/DashboardService";
import GlobalSettings from "@/services/resources/GlobalSettings";
import Antecipation from "./antecipation";
import ValidationService from "@/services/resources/ValidationService";
import CurrencyCurrenciesService from "@/services/resources/CurrencyCurrenciesService";
import CurrencyConversionService from "@/services/resources/CurrencyConversionService";
import UserSettingsService from "@/services/resources/UserSettingsService";
import PingService from '@/services/resources/PingService';
import gtagSend from '@/utils/gtagSend';

import { mapGetters, mapActions } from "vuex";
import IdWall from "../../../../mixins/IdWall";

const serviceWithdraw = WithdrawService.build();
const serviceBankAccount = BankAccountService.build();
const serviceStatement = DashboardService.build();
const serviceGlobalSettings = GlobalSettings.build();
const serviceValidation = ValidationService.build();
const serviceCurrencyCurrencies = CurrencyCurrenciesService.build();
const serviceCurrencyConversion = CurrencyConversionService.build();
const serviceUserSettings = UserSettingsService.build();
const pingService = PingService.build();

export default {
  name: "Withdraw",
  components: {
    Money,
    Antecipation,
    ModalBankAccount,
    UserValidation,
    ModalPJAccount,
    ModalAccountType
  },
  mixins: [FormatMoney, IdWall],
  props: {
    permite_antecipacao: {},
    status_antecipation:{},
    last_antecipation:{},
    loadingKyc: false,
    lastAntecipationCurrencySymbol: {
      required: false,
      type: String,
      default: "R$"
    },
    antecipation_fee: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      name: 'modal-bank-accounts',
      accounts: [
        {
          value: "",
          text: "Selecione uma conta",
        },
      ],
      amount: 0,
      amount_error: false,
      bank_account: "",
      base_coins: [],
      coins: [],
      contas: [
        {
          _infos: {
            cpf_cnpj: "",
            bank_name: "",
            agency: "",
            account: "",
            type: "",
          },
        },
      ],
      conta_selecionada: {
        cpf_cnpj: "",
        bank_name: "",
        agency: "",
        account: "",
        type: "",
        account_dv: "",
      },
      currency_symbol_avaliable: "R$",
      currency_symbol_details: "R$",
      currency_fee: 0,
      currency_conversion: 0,
      loading: false,
      loadingConvertion: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      pre_infos: true,
      status_validation: {
        status: "",
        observation: "",
      },
      selected_currency: null,
      statusAntecipationLocal: "",
      submit: false,
      valor_antecipavel: 0,
      valor_disponivel: 0,
      wallet_currency: null,
      account_type: null,
      id_modal_account_type: 'modal-account-type-withdraw',
      id_modal_identity_validation: 'identity-validation-withdraw',
      flowsAreValid: false,
      validation_initiated: false,
    }
  },
  computed: {
    showWithdrawal(){
     return this.isLoading &&
       ((!this.getFlags.includes('recipient_data') && !this.pendingIdentityValidation && this.flowBiometryIsValid) ||
        (this.getFlags.includes('recipient_data') && this.accounts.length > 1 && this.notPendingAndError &&
        this.pendingValidationAndStatusAndPjApprovel))
    },
    showAddBankAccount(){
      return this.isLoading && (this.getFlags.includes('recipient_data') && this.accounts.length === 1 && this.notPendingAndError &&
        this.pendingValidationAndStatusAndPjApprovel)
    },
    notPendingAndError(){
     return !this.pendingKyc && !this.errorCreateCelcoin.length && !this.errorKycCelcoinPF.length && (this.hasCompanyDocumentsApproved  || (!this.hasCompanyDocumentsApproved && !this.errorKycCelcoinPJ.length ))
    },
    showWithdrawalDetails(){
      return this.isLoading && !this.pre_infos &&
       ((!this.getFlags.includes('recipient_data') && !this.pendingIdentityValidation && this.flowBiometryIsValid) ||
        (this.getFlags.includes('recipient_data') && this.notPendingAndErrorWithStatus))
    },
    pendingValidationAndStatusAndPjApprovel(){
      return (!this.pendingIdentityValidation && this.flowBiometryIsValid && !this.waitingPjApprovel)
    },
    notPendingAndErrorWithStatus(){
      return (!this.pendingKyc && !this.errorCreateCelcoin.length && !this.errorKycCelcoinPF.length && !this.errorKycCelcoinPJ.length &&
        !this.pendingValidationAndStatusAndPjApprovel)
    },
    showIdentityVerification(){
      return this.isLoading && (!this.getFlags.includes('recipient_data') || (this.getFlags.includes('recipient_data') && this.pendingAndError))
    },
    pendingAndError(){
      return (this.pendingKyc || this.errorCreateCelcoin.length || this.errorKycCelcoinPF.length || (this.errorKycCelcoinPJ.length && !this.hasCompanyDocumentsApproved) || this.waitingPjApprovel)
    },
    isLoading(){
      return !this.loading && !this.loadingKyc
    },
    ...mapGetters({
      getFlags: "getFlags",
      kyc_personal_checklist: 'getKycPersonalChecklist',
      errorCreateCelcoin: 'getErrorCreateCelcoin',
      errorKycCelcoinPF: 'getErrorKycCelcoinPF',
      errorKycCelcoinPJ: 'getErrorKycCelcoinPJ',
    }),
    userId() {
      return this.$store.getters.getProfile.id;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    isWebView() {
      return window.isWebView == true;
    },
    isClient() {
      return this.$store.getters.getProfile.type === "CLIENT";
    },
    userLogged() {
      return this.$store.getters.getProfile;
    },
    hasCompanyDocuments() {
      return (
        this.userLogged &&
        this.userLogged.company &&
        this.userLogged.company.documents &&
        this.userLogged.company.documents.length &&
        this.userLogged.company.documents.some(x => x.document_type === 'CONTRATO_SOCIAL' || x.document_type === null)
      )
    },
    hasCompanyDocumentsApproved() {
      return (
        this.userLogged &&
        this.userLogged.company &&
        this.userLogged.company.documents &&
        this.userLogged.company.documents.length &&
        this.userLogged.company.documents.some(x => (x.document_type === 'CONTRATO_SOCIAL' || x.document_type === null) && x.status === 'APPROVED')
      )
    },
    pendingKyc() {
      const pendingKycPersonalChecklist = (
        !this.kyc_personal_checklist &&
        this.getFlags.includes("recipient_data")
      )

      const pendingPjDocuments = (
        (this.account_type === 'PENDING_PJ' || this.account_type === 'PJ') &&
        this.userLogged.company_id &&
        !this.hasCompanyDocuments
      )

      return this.pendingIdentityValidation || pendingKycPersonalChecklist || pendingPjDocuments
    },
    pendingKycPersonalChecklist() {
      return (
        !this.kyc_personal_checklist &&
        this.getFlags.includes("recipient_data")
      )
    },
    pendingPjDocuments() {
      return (
        (this.account_type === 'PENDING_PJ' || this.account_type === 'PJ') &&
        this.userLogged.company_id &&
        !this.hasCompanyDocuments
      )
    },
    waitingPjApprovel() {
      return (
        (this.account_type === 'PENDING_PJ' || this.account_type === 'PJ') &&
        this.userLogged.company_id &&
        this.hasCompanyDocuments &&
        !this.hasCompanyDocumentsApproved
      )
    }
  },
  watch: {
    amount() {
      this.amount_error = this.amount ? false : true
      this.fetchCurrencyConversion();
    }
  },
  methods: {
    closeAccountType(type){
      this.account_type = type;

      const pendingPjDocuments = (
        (type === 'PENDING_PJ' || type === 'PJ') &&
        this.userLogged.company_id &&
        !this.hasCompanyDocuments
      )

      if(pendingPjDocuments) {
        this.openPJModal()
      } else if(this.errorKycCelcoinPF.length) {
        this.openVerification()
      } else if(this.errorKycCelcoinPJ.length) {
        this.openPJModal()
      }

    },
    ...mapActions([
      'fetchUserSettings'
    ]),
    setPending(){
      this.statusAntecipationLocal = this.status_antecipation
      this.statusAntecipationLocal = 'REQUESTED';
      this.$emit("pending");
    },
    close() {
      this.resetData()
      this.$emit("close")
    },
    fetchGlobalSettings() {
      let data = {
        keys: "SELLER_WITHDRAW_FEE",
      };
      serviceGlobalSettings.search(data).then((response) => {
        response.forEach((item) => {
          if (item.key == "SELLER_WITHDRAW_FEE") {
            this.taxa_saque = +item.value;
          }
        });
      });
    },
    initVerification() {
      if(this.isWebView) {
        if (this.getFlags.includes("idwall")) {
          window.flutter_inappwebview.callHandler('completeCheck');
        }else {
          console.log('teste1')
          this.$bvModal.show(this.id_modal_identity_validation ? this.id_modal_identity_validation : "identity-validation");
        }

      } else {
        if (this.getFlags.includes("idwall")) {
          this.$bvModal.show("user-validation");
        } else {
          console.log('teste2')
          this.$bvModal.show(this.id_modal_identity_validation ? this.id_modal_identity_validation : "identity-validation");
        }
      }
    },
    async getStatusValidation(origem = null) {
      this.loading = true;
      let data = { id: "status" };
      await serviceValidation
        .read(data)
        .then(res => {
          this.status_validation = res;
          if (!this.status_validation.status) {
            this.status_validation = {
              status: "INITIATED",
              observation: "",
            };
          }

          if(origem === 'close_modal' && (this.status_validation.status === 'INITIATED' || this.status_validation.status === 'REQUESTED')) {
            const pendingKycPersonalChecklist = (
              !this.kyc_personal_checklist &&
              this.getFlags.includes("recipient_data")
            )

            const pendingPjDocuments = (
              (this.account_type === 'PENDING_PJ' || this.account_type === 'PJ') &&
              this.userLogged.company_id &&
              !this.hasCompanyDocuments
            )
            if(this.getFlags.includes("recipient_data")) {
              if(pendingKycPersonalChecklist) {
                this.openAccountTypeModal()
              } else if(!pendingKycPersonalChecklist && pendingPjDocuments) {
                this.openPJModal()
              } else if(this.errorKycCelcoinPJ.length) {
                this.openPJModal()
              }
            }
          }
        })
        .catch(err =>  console.error(err))
        .finally(() => { this.loading = false })
    },
    openAccountTypeModal(){
      this.$bvModal.show(this.id_modal_account_type ? this.id_modal_account_type : 'modal-pf-account')
    },
    openPJModal() {
      this.$bvModal.show('modal-pj-account')
    },
    openVerification() {
      const openPj = !this.isClient &&
            this.userLogged.company_id &&
            (this.account_type === 'PJ' || this.account_type === 'PENDING_PJ') &&
            !this.hasCompanyDocuments &&
            ['APPROVED', 'REQUESTED'].includes(this.status_validation.status)
      if (this.isWebView) {
        if (this.getFlags.includes("idwall")) {
          if (openPj) {
            window.flutter_inappwebview.callHandler('openPJVerification');
          } else {
            window.flutter_inappwebview.callHandler('completeCheck');
          }
        }else {
          if (openPj) {
            window.flutter_inappwebview.callHandler('openPJVerification');
          } else {
            console.log('teste3')
            this.$bvModal.show(this.id_modal_identity_validation ? this.id_modal_identity_validation : "identity-validation");
          }
        }
      } else {
        if (this.getFlags.includes("idwall")) {
          this.$bvModal.show("user-validation");
        } else {
          if (openPj) {
            this.openPJModal()
          } else {
            console.log('teste4')
            this.$bvModal.show(this.id_modal_identity_validation ? this.id_modal_identity_validation : "identity-validation");
          }
        }
      }
    },
    fetchReports(currency_id) {
      if(!currency_id) currency_id = 1
      this.loading = true
      let data = {
        types: ["statements"],
        currency_id
      };

      serviceStatement
        .search(data)
        .then((response) => {
          this.valor_disponivel = response.statementsAvailable
          this.valor_antecipavel = response.statementsCanBeAntecipated
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false })
    },
    openAntecipation() {
      gtagSend('solicitacao_antecipacao_clicada', {
          ID_Cliente: JSON.parse(localStorage.user).id || this.$store.getters.getProfile.id,
      });
      this.$bvModal.show("antecipation");
    },
    openBanks() {
      this.$bvModal.show(this.name);
    },
    updateBankAccount() {
      if(this.bank_account) {
        // get account object from id
        const conta = this.contas.find(item => item.id == this.bank_account);
        // set select currency options "this.coins" with bank currencies avaliable
        let coins = [];
        if(conta.currencies) {
          this.base_coins.forEach(coin => {
            if(conta.currencies.some(currency => coin.id === currency.id)) coins.push(coin)
          })
        } else coins = [];
        this.coins = coins;
        this.pre_infos = false;
        this.conta_selecionada = {
          cpf_cnpj: conta.cpf_cnpj,
          bank_name: conta.bank_name,
          agency: conta.agency,
          account: conta.account,
          account_dv: conta.account_dv,
          type: conta.type,
          name: conta.name,
          international_type: conta.international_type,
          bic_swift: conta.bic_swift,
          currencies: conta.currencies,
          country: conta.country,
          company_id: conta.company_id,
          account_type: conta.account_type
        };
        this.currency_symbol_details = this.wallet_currency.currency_symbol;
      } else {
        this.pre_infos = true
        this.currency_symbol_avaliable = this.isHeaven ? "$" : "R$";
        this.currency_symbol_details = this.isHeaven ? "$" : "R$";
      }
      this.selected_currency = null
    },
    roundValue(num, places) {
      return +parseFloat(num).toFixed(places);
    },
    onSubmit() {
      let typeDoc = this.conta_selecionada.company_id && this.conta_selecionada.account_type === 'BUSINESS' ? 'cnpj' : 'cpf'
      // validate all required fields
      this.$validator.validateAll().then((result) => {
        if (result) {
          // validate withdrow value
          if (!this.amount) {
            this.amount_error = true;
            this.$bvToast.toast(
              this.$t('seller.statements.modals.withdraw.text_851'), {
                title: this.$t('views.seller.statements.withdraw'),
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
            return;
          }
          // validate if withdrow value is smaller than available balance
          if (this.amount > this.roundValue(this.valor_disponivel, 2)) {
            this.amount_error = true;
            this.$bvToast.toast(
              this.$t('seller.statements.modals.withdraw.text_860'), {
                title: this.$t('views.seller.statements.withdraw'),
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
            return;
          }
          // Validates if you are an individual and if you are heaven, the withdrawal value is a maximum of 500, otherwise, a maximum value of 2000

          this.loading = true;
          this.amount_error = false;

          let data = {
            conversion: this.currency_conversion !== 0 && this.currency_conversion != null ? true : false,
            data: {
              amount: {
                requested_amount: this.amount,
                // converted_data: {
                //   from: this.wallet_currency.currency,
                //   to:  this.selected_currency.currency,
                //   converted_amount: this.currency_conversion,
                //   currency_rate: this.currency_fee
                // },
              },
              account_withdrawal: {
                account_id: this.bank_account,
                // bank_currency_id: this.selected_currency.id,
                wallet_currency_id: this.wallet_currency.id
              }
            }
          }

          serviceWithdraw
            .create(data)
            .then(() => {
              // if(debounceCookies('csat_withdraw')) {
              //   _refiner('showForm', 'csat_withdraw')
              // }
              gtagSend('clique_botao_saque', {
                  ID_Cliente: JSON.parse(localStorage.user).id || this.$store.getters.getProfile.id,
              });
              this.amount = 0
              this.bank_account = ""
              this.pre_infos = true

              this.$bvToast.toast(
                this.$t('seller.statements.modals.withdraw.text_853'),
                {
                  title: this.$t('views.seller.statements.withdraw'),
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                }
              );
              this.resetData()
              this.$emit("close")
              this.$bvModal.hide("withdraw-request")
            })
            .catch(err => console.error(err))
            .finally(() => { this.loading = false })
        }
      })
    },
    openModal() {
      if(JSON.parse(localStorage.user).country) {
        if(JSON.parse(localStorage.user).country.base_currency) {
          this.wallet_currency = JSON.parse(localStorage.user).country.base_currency
        }
      }
      this.fetchReports(this.wallet_currency.id)
      this.currency_symbol_avaliable = this.wallet_currency.currency_symbol;
      this.money.prefix = this.wallet_currency.currency_symbol + " ";
      this.fetchAccounts(this.wallet_currency.id);
      this.currency_symbol_avaliable = this.isHeaven ? '$' : 'R$'
      this.currency_symbol_details = this.isHeaven ? '$' : 'R$'
      this.money.prefix = this.isHeaven ? '$' : 'R$'
    },
    fetchAccounts(currency_id) {
      this.loading = true
      this.accounts = [{ value: "", text: this.$t('seller.statements.modals.withdraw.text_861') }]
      let accounts_details = []
      let data = {
        // currency_id: currency_id = this.isHeaven ? currency_id : 1
        currency_id: this.isHeaven ? 2 : 1
      }
      serviceBankAccount
        .search(data)
        .then((response) => {
          response.forEach((element) => {
            if(element.bic_swift != "" && element.bic_swift != null) {
              this.accounts.push({
                value: element.id,
                text: element.account + " - " + element.name,
              });
              accounts_details.push({
                id: element.id,
                name: element.name,
                international_type: element.international_account_type,
                account: element.account,
                bic_swift: element.bic_swift,
                country: element.country.country_code,
                currencies: element.currencies,
                company_id: element.company_id,
                account_type: element.account_type
              });
            } else {
              this.accounts.push({
                value: element.id,
                text: element.account + " - " + element.bank_name,
              });
              accounts_details.push({
                id: element.id,
                cpf_cnpj: element.cpf_cnpj,
                bank_name: element.bank_name,
                agency: element.agency,
                account: element.account,
                account_dv: element.account_dv,
                type: element.type,
                currencies: element.currencies,
                company_id: element.company_id,
                account_type: element.account_type
              });
            }
          });
        })
        .catch(err => console.error(err))
        .finally(() => {
          this.contas = accounts_details;
          this.loading = false;
        });
    },
    /**
     * Get currencies from back api where is base currency and set "this.coins" in order: BRL > USD > EUR > GBP
     * @params no params
     * @return no return
     * @author Sandro Souza
     */
    fetchCurrencyCurrencies() {
      let data = { is_base_currency: 1 };
      serviceCurrencyCurrencies
        .search(data)
        .then((response) => {
          this.coins = response.sort((a, b) => {
            if (a.id > b.id) return 1;
            if (a.id < b.id) return -1;
            return 0;
          })
          this.base_coins = response.sort((a, b) => {
            if (a.id > b.id) return 1;
            if (a.id < b.id) return -1;
            return 0;
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          if(this.coins[1].currency === 'USD') this.coins[1].currency_symbol = '$'
        });
    },
    updateCurrency() {
      if(this.selected_currency) this.currency_symbol_details = this.selected_currency.currency_symbol;
      this.fetchCurrencyConversion();
    },
    resetData() {
      this.currency_symbol = this.isHeaven ? "$" : "R$"
      this.money.prefix = this.isHeaven ? "$" : "R$"
      this.selected_currency = null
      this.bank_account = ""
      this.conta_selecionada = {}
      this.amount = 0
    },
    fetchCurrencyConversion() {
      if(this.wallet_currency && this.selected_currency){
        this.loadingConvertion = true;
        let data = {
          currency_from: this.wallet_currency.currency,
          currency_to: this.selected_currency.currency,
          amount: this.amount
        };
        serviceCurrencyConversion
          .create(data)
          .then((response) => {
            this.currency_conversion = parseFloat(response.result);
          })
          .catch(err => console.error(err))
          .finally(() => {
            this.loadingConvertion = false;
            this.updateFee();
          })
      }
    },
    updateFee() {
      let currency_fee = {};
      currency_fee = this.conta_selecionada.currencies.find(c => c.id === this.selected_currency.id).fee_conversion
      if(currency_fee != null && this.selected_currency != this.wallet_currency) {
        if(currency_fee.type === "fixed") this.currency_fee = currency_fee.fee;
        else this.currency_fee = this.currency_conversion * currency_fee.fee;
      } else this.currency_fee = 0;
    },
    updateCoin() {
      if(this.wallet_currency) this.currency_symbol_avaliable = this.wallet_currency.currency_symbol
      this.money.prefix = this.currency_symbol_avaliable + " "
      this.fetchReports(this.wallet_currency.id)
      this.fetchCurrencyConversion()
      this.fetchAccounts(this.wallet_currency.id)
    },
    setColors(coin) {
      if(coin === 'Real Brasileiro') return "background-color: rgba(0, 132, 92, 1); color: rgba(255, 203, 55, 1)"
      if(coin === 'Dólar Americano') return "background-color: rgba(255, 203, 55, 1); color: rgba(0, 0, 0, 1)"
      if(coin === 'Euro') return "background-color: rgb(40 30 176); color:rgba(255, 203, 55, 1)"
      if(coin === 'Libra') return "background-color: rgba(0, 0, 0, 1); color: rgba(255, 203, 55, 1)"
      return "background-color: #21a56e; color: rgba(255, 203, 55, 1)"
    },
    async fetchAccountType() {
      const userId = this.userId || JSON.parse(localStorage.user).id
      await serviceUserSettings.search({url: userId, filter: ['ACCOUNT_TYPE']})
        .then(res => {
          if(res.some(x => x.key === 'ACCOUNT_TYPE')) {
            this.account_type = (res.find(x => x.key === 'ACCOUNT_TYPE')).value
          } else {
            this.account_type = null
          }
        })
        .catch(err => console.error(err))
    },
    async initiateFlow(){
      pingService.create().then(res => {
        if (res.success && res.validation_initiated){
          this.validation_initiated = res.validation_initiated;
        }
      }).catch(err => console.error(err))
    }
  },

  async mounted() {
    await this.fetchUserSettings()
    await this.$store.dispatch("fetchFlags");
    await this.getStatusValidation();
    await this.fetchAccountType();
    await this.getFlowValidation();

    if (!this.flowsAreValid){
      this.initiateFlow();
    }

    this.$emit('stop-loading')

    const pendingKycPersonalChecklist = (
      !this.kyc_personal_checklist &&
      this.getFlags.includes("recipient_data")
    )

    const pendingPjDocuments = (
      (this.account_type === 'PENDING_PJ' || this.account_type === 'PJ') &&
      this.userLogged.company_id &&
      !this.hasCompanyDocuments
    )

    if(this.getFlags.includes("recipient_data")){
      if(this.pendingIdentityValidation && (!this.isWebView || this.isWebView && this.getFlags.includes('idwall'))){
        this.openVerification()
      } else if(!this.pendingIdentityValidation && pendingKycPersonalChecklist) {
        this.openAccountTypeModal()
      } else if(!this.pendingIdentityValidation && !pendingKycPersonalChecklist && pendingPjDocuments) {
        this.openPJModal()
      } else if(this.errorCreateCelcoin.length) {
        this.openAccountTypeModal()
      } else if(this.errorKycCelcoinPF.length) {
        this.openVerification()
      } else if(this.errorKycCelcoinPJ.length && !this.hasCompanyDocumentsApproved) {
        this.openPJModal()
      }
    }
  },
  async created() {
    this.wallet_currency = this.isHeaven ? {
      id: 2,
      currency: "USD",
      currency_symbol: "$"
    } : {
      id: 1,
      currency: "BRL",
      currency_symbol: "R$"
    }
    EventBus.$on("updateBanks", () => this.fetchAccounts(this.wallet_currency.id));
    this.fetchGlobalSettings();
    this.fetchCurrencyCurrencies();
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/variables.scss";

.amount-available {
  font-size: 34px;
  color: $base-color;
  font-weight: 600;
}

.btn-green {
  display: inline-block;
  border-radius: 5px;
  padding: 7px 20px;
  border: 1px $base-color solid;
  width: auto;
  color: #fff;
  background: $base-color;
  cursor: pointer;

  &:hover {
    background: #fff;
    color: $base-color;
    border: 1px $base-color solid;
  }
}
.item-valor {
  display: block;
  padding: 15px 0;
}
.item-valor + .item-valor {
  border-top: 1px solid #ededf0;
}
.item-valor span {
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
}
.item-valor p {
  font-size: 28px;
  font-weight: 600;
  margin: 10px 0;
}
@media screen and (max-width: 768px) {
  .item-valor p {
    font-size: 13px;
  }
}
.saldo-livre {
  color: #2133d2;
}
.item-valor p.taxa {
  font-size: 18px;
}
.btn-conta-corrente {
  display: flex;
  align-items: center;
  color: #333;
  background: #f7f7f7;
  font-weight: 600;
  font-size: 14px;
  padding: 0 20px !important;
  margin-left: 15px;
  margin-top: -15px;
}
@media screen and (max-width: 768px) {
  .btn-conta-corrente {
    margin-left: 0;
    margin-top: 0px;
    font-size: 13px;
  }
}
.btn-conta-corrente img {
  filter: brightness(1) invert(1);
  min-width: 18px;
}
.select-contas-bancarias {
  width: 350px;
  max-width: 100%;
}

// novos saque
.topico {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 15px;
}

.saldo-info {
  font-size: 13px;
  color: #81858e;
  margin-bottom: 20px;
}
.saldo-info span {
  font-weight: 600;
  color: #2133d2;
}

.text-info .info{
  font-size: 13px;
  color: #2133d2;
  margin-bottom: 20px;
}

.text-info .error{
  font-size: 13px;
  color: #ff0c37;
  margin-bottom: 20px;
}

.detalhes-saque .item-detalhe {
  padding: 15px 10px;
}
.detalhes-saque .item-detalhe + .item-detalhe {
  margin-top: 10px;
  border-top: 1px solid #ededf0;
}
.detalhes-saque .item-detalhe span {
  font-size: 12px;
  color: #2133d2;
  margin-bottom: 5px;
  display: block;
}
.detalhes-saque .item-detalhe .info-detalhe {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
}
.detalhes-saque .item-detalhe .info-detalhe + .info-detalhe {
  margin-top: 5px;
}
.detalhes-saque .item-detalhe .info-detalhe p {
  color: #81858e;
}
.detalhes-saque .item-detalhe .info-detalhe p.value-info {
  color: #333;
}

.topico_detalhes {
  margin-left: 10px;
  margin-bottom: 0;
}
.taxa-info {
  color: #ff0c37 !important;
}

.sem-contas {
  text-align: center;
  padding: 15px;
}
.sem-contas p {
  color: #81858e;
  font-size: 14px;
}

.item-top {
  display: flex;
  align-items: center;
  gap: 15px;
}
.item-top img {
  max-width: 35px;
}
.item-top + .item-top {
  margin-top: 20px;
}
.item-top span {
  font-size: 12px;
  font-weight: normal;
  color: #81858e;
}
.item-top p {
  font-size: 14px;
  font-weight: 600;
}

.item-top p.visibled {
  background: transparent;
  color: #333;
}
.item-antecipa {
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .item-antecipa {
    display: block;
  }
  .item-antecipa #quero-antecipar {
    margin-top: 15px;
  }
}
.btn-saldo {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.verificao p {
  color: #81858e;
  font-size: 14px;
}
.verificao button {
  margin-top: 30px;
}

.btn-2 {
  color: #2133d2;
  background: rgba(33, 51, 210, 0.1);
  font-size: 12px;
  font-weight: 500;
  padding: 10px 15px;
  border-radius: 5px;
}
.aprovacao {
  display: flex;
  align-items: flex-start;
}
.aprovacao img {
  margin-right: 10px;
}
.DISAPPROVED {
  background: rgba(255, 12, 55, 0.1);
  color: #ff0c37;
}
.APPROVED {
  background: rgba(0, 148, 136, 0.1);
  color: #009488;
}
.chip {
  width: 30px;
  height: 30px;
  border:  1px solid transparent;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}
</style>
